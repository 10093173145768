// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyA33OLymFDcN76t5k-d37_-B1FI9UU1DMw",
    authDomain: "solo-delivery.firebaseapp.com",
    databaseURL: "https://solo-delivery.firebaseio.com",
    projectId: "solo-delivery",
    storageBucket: "solo-delivery.appspot.com",
    messagingSenderId: "143260228572",
    appId: "1:143260228572:web:c859d5cf7869f220334d19",
    measurementId: "G-FH8L8M1ZM3",
  },
  onesignal: {
    appId: "b5ef193b-281d-4387-b49b-458a5a9b23f8",
    googleProjectNumber: "143260228572",
    restKey: "NTE3N2Q3MzEtOTVkYi00ZjRkLWI2YTUtYWYyOWIzMGMyOGM5",
  },
  stripe: {
    sk: "",
  },
  general: {
    symbol: "RON",
    code: "lei",
  },

  api: "http://localhost:5001/solo-delivery/us-central1/api",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
