import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { AuthGuard } from "./guard/auth.guard";
import { SetupAuthGuard } from "./setupGuard/auth.guard";
import { Role } from "./shared/helpers/role";
const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "admin-dashboard",
        pathMatch: "full",
      },

      {
        path: "admin-dashboard",
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Dispatcher] },
      },
      {
        path: "basic",
        loadChildren: () =>
          import("./components/basic/basic.module").then((m) => m.BasicModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "notifications",
        loadChildren: () =>
          import(
            "./components/advance/notifications/notifications.module"
          ).then((m) => m.NotificationsModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "forms",
        loadChildren: () =>
          import(
            "./components/forms/basic-elements/basic-elements.module"
          ).then((m) => m.BasicElementsModule),
      },
      {
        path: "bootstrap-table",
        loadChildren: () =>
          import(
            "./components/tables/bootstrap-table/basic-bootstrap/basic-bootstrap.module"
          ).then((m) => m.BasicBootstrapModule),
      },
      {
        path: "map",
        loadChildren: () =>
          import("./map/google-map/google-map.module").then(
            (m) => m.GoogleMapModule
          ),
      },
      {
        path: "simple-page",
        loadChildren: () =>
          import("./simple-page/simple-page.module").then(
            (m) => m.SimplePageModule
          ),
      },
      {
        path: "admin-restaurants",
        loadChildren: () =>
          import("./restaurants/restaurants.module").then(
            (m) => m.RestaurantsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-drivers",
        loadChildren: () =>
          import("./drivers/drivers.module").then((m) => m.DriversModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Dispatcher] },
      },
      {
        path: "admin-users",
        loadChildren: () =>
          import("./users/users.module").then((m) => m.UsersModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-orders",
        loadChildren: () =>
          import("./orders/orders.module").then((m) => m.OrdersModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Dispatcher, Role.Venue] },
      },
      {
        path: "admin-banners",
        loadChildren: () =>
          import("./banners/banners.module").then((m) => m.BannersModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-notification",
        loadChildren: () =>
          import("./notification/notification.module").then(
            (m) => m.NotificationModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-coupons",
        loadChildren: () =>
          import("./coupons/coupons.module").then((m) => m.CouponsModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-rest-details",
        loadChildren: () =>
          import("./restdetails/restdetails.module").then(
            (m) => m.RestdetailsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-restaurant-menu",
        loadChildren: () =>
          import("./restaurant-menu/restaurant-menu.module").then(
            (m) => m.RestaurantMenuModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-new-coupon",
        loadChildren: () =>
          import("./newcoupons/newcoupons.module").then(
            (m) => m.NewcouponsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-support",
        loadChildren: () =>
          import("./supports/supports.module").then((m) => m.SupportsModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-chats",
        loadChildren: () =>
          import("./chats/chats.module").then((m) => m.ChatsModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-newbanner",
        loadChildren: () =>
          import("./newbanner/newbanner.module").then((m) => m.NewbannerModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-newdriver",
        loadChildren: () =>
          import("./newdriver/newdriver.module").then((m) => m.NewdriverModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Dispatcher] },
      },
      {
        path: "admin-neworder",
        loadChildren: () =>
          import("./neworder/neworder.module").then((m) => m.NeworderModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Dispatcher, Role.Venue] },
      },
      {
        path: "admin-userdetails",
        loadChildren: () =>
          import("./userdetails/userdetails.module").then(
            (m) => m.UserdetailsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-orderdetails",
        loadChildren: () =>
          import("./orderdetails/orderdetails.module").then(
            (m) => m.OrderdetailsModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin, Role.Dispatcher] },
      },
      {
        path: "admin-cities",
        loadChildren: () =>
          import("./cities/cities.module").then((m) => m.CitiesModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-newcities",
        loadChildren: () =>
          import("./newcities/newcities.module").then((m) => m.NewcitiesModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "admin-rest-stats",
        loadChildren: () =>
          import("./stats/stats.module").then((m) => m.StatsModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      {
        path: "manage-user-roles",
        loadChildren: () =>
          import("./manage-user-roles/manage-user-roles.module").then(
            (m) => m.ManageUserRolesModule
          ),
        canActivate: [AuthGuard],
        data: { roles: [Role.Admin] },
      },
      //
    ],
  },
  {
    path: "",
    component: AuthComponent,
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./auth/auth.module").then((m) => m.AuthModule),
        canActivate: [SetupAuthGuard],
      },
      {
        path: "report",
        loadChildren: () =>
          import("./reports/reports.module").then((m) => m.ReportsModule),
      },
      {
        path: "setup",
        loadChildren: () =>
          import("./setup/setup.module").then((m) => m.SetupModule),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "dashboard",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
